import {AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {OpenGroundStock} from '../openGroundStock';
import {OpenGroundStockService} from '../open-ground-stock.service';
import {ActivatedRoute} from '@angular/router';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {catchError, debounceTime, distinctUntilChanged, filter, finalize, switchMap, tap} from 'rxjs/operators';
import {WithScrollContainer} from '../../../_helpers/with-scroll-container';

@Component({
  selector: 'app-open-ground-stock-search',
  templateUrl: './open-ground-stock-search.component.html',
  styleUrls: ['./open-ground-stock-search.component.scss']
})
export class OpenGroundStockSearchComponent extends WithScrollContainer() implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('searchInput', { static: true }) searchInput: ElementRef;

  displayedColumns = ['plantName', 'location', 'rest', 'productionDate'];
  dataSource: MatTableDataSource<OpenGroundStock> = new MatTableDataSource<OpenGroundStock>();
  loadingSubject = new BehaviorSubject<boolean>(false);
  searchTermSubject = new BehaviorSubject<string>('');
  loading$ = this.loadingSubject.asObservable();
  noResultsFound = false;
  savedScrollPosition: number;
  searchTerm: string;

  constructor(
    private openGroundStockService: OpenGroundStockService,
    private route: ActivatedRoute
  ) {
    super();
   }

  ngOnInit() {
    this.setupSearchFieldBehaviour();
    this.searchTerm = this.route.snapshot.paramMap.get('searchTerm');
    this.searchTermSubject.next(this.searchTerm);
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }

  ngOnDestroy() {
    this.searchTermSubject.unsubscribe();
    this.loadingSubject.unsubscribe();
  }

  public async onAttach() {
    if (this.searchTerm) {
      this.findOpenGroundStock(this.searchTerm).subscribe(stock => {
        this.dataSource.data = stock;
        if (this.savedScrollPosition) {
          this.scrollContainer.nativeElement.scrollTo(0, this.savedScrollPosition);
          this.savedScrollPosition = undefined;
        }
      });
    } else {
      this.searchInput.nativeElement.focus();
    }
  }

  findOpenGroundStock(searchTerm: string): Observable<OpenGroundStock[]> {
    if (!searchTerm) {
      return of([]);
    }
    this.loadingSubject.next(true);
    return this.openGroundStockService.findByCodeOrName(searchTerm).pipe(
      catchError(() => of([])),
      tap(result => this.noResultsFound = result.length === 0),
      finalize(() => this.loadingSubject.next(false))
    );
  }

  private setupSearchFieldBehaviour() {
    this.searchTermSubject.pipe(
      filter(str => str && str.length >= 2),
      debounceTime(1000),
      distinctUntilChanged(),
      switchMap(search => this.findOpenGroundStock(search)),
    ).subscribe(voorraad => this.dataSource.data = voorraad);
  }
}

